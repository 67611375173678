<template>
  <div>
    <nav-filter
      class="mb-10"
      :title="$t('form.choose_class')"
      :filters="filters"
      @reFilter="reFilter"
      :loading="loadingClasses"
      :collapseAction="true"
    />

    <!-- Table -->
    <gradeBookTable />
  </div>
</template>
<script>
// Components
import NavFilter from '@/components/general/NavFilter.vue'
import gradeBookTable from '@/modules/grading/components/gradeBookTable/gradeBookTable.vue'
// mixins
import {commonDataMixin} from '@/mixins.js'

// Repos
import {
  RepositoryFactory
} from '@/Repositories/RepositoryFactory'

const TeacherRepository = RepositoryFactory.get('TeachersModule', 'BasicInfo'),
  ExamRepo = RepositoryFactory.get('ExamModule', 'Exam')

export default {
  name: 'gradebook',
  mixins: [commonDataMixin],
  components: {
    NavFilter,
    gradeBookTable
  },
  data () {
    return {
      classes: [],
      loadingClasses: true,
      /////////////////////
      //  Filters
      /////////////////////
      filters: [
        {
          name: this.$t('basic.grades'),
          payload_name: 'grades',
          onChangeFireAction: true,
          by: []
        },

        {

          name: this.$t('basic.classes'),
          payload_name: 'class',
          by: []
        }

      ],
      filtersChoosen: []

    }
  },
  computed: {
    selectedClass () {
      return this.$store.getters.getSelectedClass || {}
    }
  },
  methods: {
    /////////////////////////
    // Loading
    /////////////////////////
    // Table Data
    async startLoadingTable () {
      await (this.loadingTable = true)
    },
    endLoadingTable () {
      this.loadingTable = false
    },
    /////////////////////////
    // refilters
    /////////////////////////

    reFilter (filters) {
      this.filtersChoosen = filters
      const
        isClassSelected = Boolean(filters.class?.id),
        classNotChanged = isClassSelected && (this.selectedClass?.id === filters.class?.id)

      if (!filters.grades) {
        this.$store.commit('gradingSelectClass', null)
        this.classes = this.filters[1].by = []
      }

      // this.selectedClass = classNotChanged ? {} : {
      //   ...filters?.class,
      //   grade_id:filters?.grades?.id,
      //   section_id:filters?.grades?.section_id,
      //   division_id:filters?.grades?.division_id
      // } || {}

      if (!classNotChanged) this.$store.commit('gradingSelectClass', {
        ...filters?.class,
        grade_id: filters?.grades?.id,
        section_id: filters?.grades?.section_id,
        division_id: filters?.grades?.division_id
      })
      else this.$store.commit('gradingSelectClass', null)
    },
    resetFilter (filter) {
      const startIndex = this.filters.findIndex(e => e.payload_name === filter)
      for (let i = startIndex; i < this.filters.length; i++) {
        this.filters[i].by = []
      }
    },

    /////////////////////////
    // APIs
    /////////////////////////
    // Get Classes
    getClasses () {
      this.loadingClasses = true
      TeacherRepository.getClasses()
        .then((res) => {
          this.classes = this.filters[0].by = res.data
        })
        .finally(() => { this.loadingClasses = false })
    },
    getAllGrades () {
      this.loadingClasses = true
      ExamRepo.getAllGrades()
        .then((res) => {
          this.grades = this.filters[0].by = res.data
          // this.filters[0].by[0].selected = true
          // this.selectedClass = this.classes[0]

        })
        .finally(() => { this.loadingClasses = false })
    },
    getFilteredClasses () {
      if (!this.filtersChoosen?.grades?.id) return
      this.resetFilter('class')

      this.loadingClasses = true
      ExamRepo.getFilteredClasses(this.filtersChoosen.grades.id)
        .then((res) => {
          this.classes = this.filters[1].by = res.data
        })
        .finally(() => { this.loadingClasses = false })
    }

  },
  created () {
    this.getAllGrades()
    this.$root.$on('onChange:grades', () => { this.getFilteredClasses() })
  },
  beforeDestroy () {
    this.$root.$off('onChange:grades')
    // this.$store.commit('gradingSelectClass', null)
  }

}
</script>
